<template>
  <div>
    <b-card>
      <div v-if="$can('create', 'Super Admin')" class="row d-flex align-items-center justify-content-end">
        <b-link to="user-create" class="add-btn btn">
          <div class="d-flex flex-row align-items-center">
            <span class="pr-1"> Add Super-Admin </span>
            <span>
              <img
                src="@/assets/images/icons/add-icon.png"
                alt="add icon"
                height="20"
                width="20"
              />
            </span>
          </div>
        </b-link>
      </div>
    </b-card>
    <b-card class="table-responsive">
      <b-table striped hover :items="users" :fields="fields">
        <template #cell(id)="row">
          {{ row.index + 1 }}
        </template>
        <template #cell(action)="row">
          <b-link
            v-if="row.item.status == 'Active' && $can('view', 'Super Admin')"
            :to="`/view-user/${row.item.id}`"
            class="btn btn-info btn-sm"
          >
            View
          </b-link>
          <b-button
            v-if="
              row.item.status == 'Active' &&
              $can('suspend', 'Super Admin') &&
              row.item.id !== 1
            "
            @click.prevent="suspendUser(row.item.id)"
            class="btn btn-danger btn-sm mx-1"
          >
            Suspend
          </b-button>
          <b-button
            v-if="
              row.item.status == 'Suspended' && $can('activate', 'Super Admin')
            "
            @click.prevent="activeUser(row.item.id)"
            class="btn btn-success btn-sm mx-1"
          >
            Activate
          </b-button>
        </template>
      </b-table>
    </b-card>
  </div>
</template>
<script>
import { toast } from "@/utils/toast";
import {
BBadge,
BButton,
BCalendar,
BCard,
BCol,
BContainer,
BDropdown,
BDropdownItem,
BDropdownItemButton,
BFormCheckbox,
BFormGroup,
BFormInput,
BLink,
BMedia,
BPagination,
BRow,
BTable,
BTooltip,
} from "bootstrap-vue";
export default {
  components: {
    BFormGroup,
    BFormCheckbox,
    BCard,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BDropdownItemButton,
    BContainer,
    BRow,
    BCol,
    BContainer,
    BCalendar,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      users: [],
      fields: [
        {
          key: "id",
          label: "id",
          sortable: true,
        },
        {
          key: "name",
          label: "Name",
          sortable: true,
        },

        {
          key: "phone",
          label: "Phone",
        },
        {
          key: "email",
          label: "email",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "action",
          label: "action",
        },
      ],
    };
  },
  mounted() {
    this.getUser();
  },
  methods: {
    getUser() {
      this.$store.dispatch("user/getUsers").then(({ data }) => {
        this.users = data;
        this.total = data.total;
      });
    },
    suspendUser(item) {
      this.$store
        .dispatch("user/suspendUser", item)
        .then((response) => {
          if (response.status_code === 200) {
            toast("Suspended", "CheckCircleIcon", "danger", "User Suspended");
            this.getUser();
          }
        })
        .catch((error) => {
          toast(
            "Error",
            "AlertTriangleIcon",
            "danger",
            "Error Suspending User"
          );
        });
    },
    activeUser(item) {
      this.$store
        .dispatch("user/activeUser", item)
        .then((response) => {
          if (response.status_code === 200) {
            toast("Activated", "CheckCircleIcon", "success", "User Activated");
            this.getUser();
          }
        })
        .catch((error) => {
          toast(
            "Error",
            "AlertTriangleIcon",
            "danger",
            "Error Activating User"
          );
        });
    },
  },
};
</script>
<style scoped>
.add-btn {
  border-color: white !important;

  /* Gradient */

  background: linear-gradient(#01185e 0%, #264296 44.92%, #7190ef);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
</style>
